<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
        <validation-provider
          name="Поточний пароль"
          :rules="{ required: true }"
          v-slot="validationContext"
        ><!--          mode="lazy"-->
          <b-form-group id="example-input-group-1" label="Поточний пароль" label-for="oldpass">
            <b-input-group>    
                <b-form-input
                id="oldpass"
                name="oldpass"
                ref="oldpass"
                v-model="form.oldpass"
                :type="(showpass1)?'text':'password'"
                :state="getValidationState(validationContext)"
                aria-describedby="oldpass-live-feedback"
                autocomplete="current-password"
                spellcheck="false" 
                autocorrect="off" 
                autocapitalize="off" 
                ></b-form-input>
                <b-input-group-append is-text>
                        <b-icon @click="showpass1=!showpass1" :icon="(showpass1)?'eye-fill':'eye-slash-fill'" role="button"></b-icon>
                    </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback id="oldpass-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-observer>
          <validation-provider name="Новий пароль" :rules="{required: true, min: 1, max: 16}" :vid="form.newpass" v-slot="validationContext">
            <b-form-group id="example-input-group-2" label="Новий пароль" label-for="newpass">
                <b-input-group>    
                    <b-form-input
                        id="newpass"
                        name="newpass"
                        ref="newpass"
                        v-model="form.newpass"
                        :type="(showpass2)?'text':'password'"
                        :state="getValidationState(validationContext)"
                        aria-describedby="newpass-live-feedback"
                        autocomplete="off"
                        spellcheck="false" 
                        autocorrect="off" 
                        autocapitalize="off" 
                    ></b-form-input>
                    <b-input-group-append is-text>
                        <b-icon @click="showpass2=!showpass2" :icon="(showpass2)?'eye-fill':'eye-slash-fill'" role="button"></b-icon>
                    </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback id="newpass-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>

          <validation-provider name="Підтвердження нового пароля" :rules="{required: true, confirmed: form.newpass}" v-slot="validationContext">
            <b-form-group id="example-input-group-3" label="Підтвердження нового пароля" label-for="newpass2">
                <b-input-group>    
                    <b-form-input
                        id="newpass2"
                        name="newpass2"
                        ref="newpass2"
                        :type="(showpass2)?'text':'password'"
                        v-model="form.newpass2"
                        :state="getValidationState(validationContext)"
                        aria-describedby="newpass2-live-feedback"
                        autocomplete="off"
                        spellcheck="false" 
                        autocorrect="off" 
                        autocapitalize="off"                 
                    ></b-form-input>
                     <b-input-group-append is-text>
                        <b-icon @click="showpass2=!showpass2" :icon="(showpass2)?'eye-fill':'eye-slash-fill'" role="button"></b-icon>
                    </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback id="newpass2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        </validation-observer>
        <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="bruvis"
            class="d-inline-block"
            @hidden="onHidden"
            >
            <b-button
                ref="button"
                type="submit"
                :disabled="busy"
                variant="bruvis"
            >
                Змінити пароль
            </b-button>
        </b-overlay>
        <b-button class="ml-2" @click="resetForm()">Reset</b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<style>

</style>

<script>
export default {
  name: 'ChangePass',  
  data() {
    return {
      form: {
        oldpass: null,
        newpass: null,
        newpass2: null
      },
      showpass1:false,
      showpass2:false,
      showpass3:false,
      busy: false,   
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        name: null,
        food: null
      };

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onSubmit() {
      this.changePass(this.form.oldpass,this.form.newpass,this.form.newpass2)
    },
    onHidden() {
        this.$refs.button.focus()
    },
    changePass(old,newp,newp2){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        },
        'withCredentials': true
      }
      this.busy = true 
      this.$axios.post('/', {"action":"changepass","oldpass":old,"newpass":newp,"newpass2":newp2,}, axiosConfig )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != "false"){
                      this.$toasted.success('Пароль змінено').goAway(6000)
                      this.busy = false 
                      this.resetForm()
                    }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        //this.needlogin = true
                        this.$toasted.error('Час сессії вичерпано').goAway(4000)
                      }else if(response.data.message != null){
                        this.$toasted.error(response.data.message).goAway(4000)
                      }else{
                        this.$toasted.error('Помилка:(').goAway(4000)
                      } 
                      this.busy = false  
                    }
                  })
                  .catch(error => {
                    this.busy = false  
                    console.error('There was an error!', error)
                    this.$toasted.error('Помилка: '+error).goAway(4000)
                  });
    }
  }
};
</script>