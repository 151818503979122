<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form 
                @submit.stop.prevent="handleSubmit(onSubmit)" 
                autocomplete="off"
                v-show="!refill_widget_is_visible"
            >
                <validation-provider
                    name="Сума поповнення"
                    :rules="{ required: true , numeric: true }"
                    v-slot="validationContext"
                >

                    <b-form-group id="refill-input-group" label="" label-for="refillsum">
                        <b-input-group prepend="₴" class="mt-3">
                            <b-form-input 
                                id="refillsum"
                                name="refillsum"
                                ref="refillsum"
                                v-model="refillsum"
                                shadow-none
                                placeholder="0"
                                :state="getValidationState(validationContext)"
                                aria-describedby="refillsum-live-feedback"
                                autocomplete="new-password"
                                spellcheck="false" 
                                autocorrect="off" 
                                autocapitalize="off" 
                            ></b-form-input>
                            <b-input-group-append>

                                <b-overlay
                                    :show="busy"
                                    rounded
                                    opacity="0.6"
                                    spinner-small
                                    spinner-variant="bruvis"
                                    class="d-inline-block"
                                    @hidden="onHidden"
                                >
                                    <b-button
                                        ref="button"
                                        type="submit"
                                        :disabled="busy"
                                        variant="bruvis"
                                    >
                                        Поповнити
                                    </b-button>
                                </b-overlay>
                            </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback id="refillsum-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

            </b-form>
        </validation-observer>
        <div id="refill_liqpay_checkout"></div>
    </div>    

</template>

<script>
export default {
  name: 'RefillCard',
  props: {

  },
  data: () => ({
      refillsum: null,
      refill_widget_is_visible: false,
      getStatusInt: null
  }),

  methods:{
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.refillBallance(this.refillsum)
    },
    onHidden() {
        this.$refs.button.focus()
    },
    refillBallance(sum){
        //this.globalbusy = true
        this.$axios.post('/', {"action":"refillballance","sum":sum} )
                  .then((response) => {

                    if(response.data.success != 'false'){                    
                      //this.globalbusy = false
                      //eslint-disable-next-line
                      LiqPayCheckout.init({
                        "data": response.data.data,
                        "signature": response.data.signature,
                        "embedTo": "#refill_liqpay_checkout",
                        "language": "uk",
                        "mode": "embed" // embed || popup
                      }).on("liqpay.callback", function(data){
                          console.log('Callback')
                          console.log(data.status)
                          console.log(data)
                          if( this.getStatusInt === null)
                            this.getStatusInt = setInterval(function(){
                              this.$axios.post('/', {"action":"getrefillstatus","rn": response.data.order_id} )
                                  .then((response) => {
                                      let result = response.data
                                      switch(result.status){
                                          case 'wait':
                                            console.log('wait')
                                            break
                                          case 'success':
                                            console.log('success')
                                            clearInterval(this.getStatusInt)
                                            this.$toasted.success(result.message).goAway(3000)
                                            //this.$parent.$emit('onRefill')              
                                            this.$emit('onRefill') 
                                            break
                                          case 'ready':
                                            console.log(result.data)
                                            clearInterval(this.getStatusInt)
                                            this.$toasted.warning(result.message).goAway(3000)
                                            break
                                          default:
                                            console.log(result)  
                                        } 

                                  })
                                  .catch(error => {
                                    console.error('There was an error!', error)
                                  });
                            }.bind(this), 2000);
                        }.bind(this)
                      ).on("liqpay.ready", function(data){

                        console.log(data.status)
                        this.refill_widget_is_visible = true
                        }.bind(this)
                      ).on("liqpay.close", function(data){
                          // close
                          console.log('Close')
                          console.log(data)
                      });
                    }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        this.$emit('needlogin')
                        console.log('auth')
                      }else{
                        this.$toasted.error(response.data.message).goAway(3000) 

                      }
                    }  
                  })
                  .catch(error => {
                    this.globalbusy = false
                    console.error('There was an error!', error)
                  });
    },

    

  }
}
</script>

<style scoped>

    @media (max-width: 575px) {

    }

    @media (hover: none) {
    
    }    

</style>

